import React from "react";

export default () => (
  <a
    href="https://www.iubenda.com/privacy-policy/77854424"
    className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed"
    title="Privacy Policy"
  >
    Privacy Policy
  </a>
);
